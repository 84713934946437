import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar";
import { AuthContextProvider } from "../context/AuthContext";
import App from "./App";
import LikedNamesPage from "./LikedNamesPage";
import Processes from "./Processes";
import Project from "./Project"; // This will now import from Project/index.js
import Projects from "./Projects";
import Scrapers from "./Scrapers";
import ServicePage from "./ServicePage";
import StatsPage from "./StatsPage";

import DynamicIcon from "../components/DynamicIcon";
import PrivateRoute from "../components/PrivateRoute"; // Import the PrivateRoute component
import Form from "./Form";
import Name from "./Name";
import NotFoundPage from "./NotFoundPage";
import Process from "./Process";
import SignIn from "./SignIn";
import Visual from "./Visual";

const Router = () => (
  <BrowserRouter>
    <AuthContextProvider>
      <DynamicIcon /> {/* Add DynamicIcon here */}
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", paddingBottom: "30px" }}>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/signin" element={<SignInWithNavbar />} />
          <Route path="/app" element={<PrivateRoute element={<WithNavbar element={<App />} />} />} />
          <Route path="/form" element={<PrivateRoute element={<WithNavbar element={<Form />} />} />} />
          <Route path="/visual" element={<PrivateRoute element={<WithNavbar element={<Visual />} />} />} />
          <Route path="/trademarking" element={<PrivateRoute element={<WithNavbar element={<Scrapers />} />} />} />
          <Route path="/projects" element={<PrivateRoute element={<WithNavbar element={<Projects />} />} />} />
          <Route path="/processes" element={<PrivateRoute element={<WithNavbar element={<Processes />} />} />} />
          <Route
            path="/scrapers/service"
            element={<PrivateRoute element={<WithNavbar element={<ServicePage />} />} />}
          />
          <Route path="/404" element={<WithNavbar element={<NotFoundPage />} />} />
          <Route
            path="/projects/:projectId"
            element={<PrivateRoute element={<WithNavbar element={<Project />} />} />}
          />
          <Route
            path="/projects/:projectId/names/:nameId"
            element={<PrivateRoute element={<WithNavbar element={<Name />} />} />}
          />
          <Route
            path="/projects/:projectId/stats"
            element={<PrivateRoute element={<WithNavbar element={<StatsPage />} />} />}
          />
          <Route path="/liked-names" element={<PrivateRoute element={<WithNavbar element={<LikedNamesPage />} />} />} />
          <Route
            path="/processes/:processName"
            element={<PrivateRoute element={<WithNavbar element={<Process />} />} />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <Footer />
    </AuthContextProvider>
  </BrowserRouter>
);

const SignInWithNavbar = () => (
  <>
    <SignIn />
  </>
);

const WithNavbar = ({ element }) => (
  <>
    <Navbar />
    <div style={{ flex: 1 }}>{element}</div>
  </>
);

const Footer = () => (
  <footer
    className="footer footer-center pt-2 bg-accent text-neutral"
    style={{ position: "fixed", bottom: 0, width: "100%" }}
  >
    <aside>
      <p className="footer-title">{process.env.REACT_APP_ENV}</p>
    </aside>
  </footer>
);

export default Router;
