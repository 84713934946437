import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BottomAnimation } from "../components/animations/BottomAnimation";
import { LeftAnimation } from "../components/animations/LeftAnimation";
import { RightAnimation } from "../components/animations/RightAnimation";
import { TopAnimation } from "../components/animations/TopAnimation";
import MainContent from "../components/MainContent";
import { UserAuth } from "../context/AuthContext";
import "../css/App.css";

function App() {
  const { user, logOut } = UserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      navigate("/signin");
    }
  }, [user, navigate]);

  if (!user?.email) {
    return null;
  }

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomAvatar = displayName => {
    const avatarURLs = {
      "Chris Daly": "https://ca.slack-edge.com/T05A5R2RSD6-U059D6DLDJ9-25c0116d4c68-512",
      "Jack Wimmer": "https://ca.slack-edge.com/T05A5R2RSD6-U059D6DLDJ9-25c0116d4c68-512",
      "Mark Liney": "https://ca.slack-edge.com/T05A5R2RSD6-U0599FVSH2A-bcc951407291-192",
      "Eden Goonesekera": "https://ca.slack-edge.com/T05A5R2RSD6-U059JK4HUVA-707968a81ad9-512",
      "Lucas Galan": "https://ca.slack-edge.com/T05A5R2RSD6-U0599G2HB62-2dd1c3f2abc0-512",
    };
    return avatarURLs[displayName] || user.photoURL || "/default-avatar.jpg";
  };

  return (
    <body className="animate-in">
      <main className="content relative">
        <TopAnimation />
        <MainContent user={user} handleSignOut={handleSignOut} getCustomAvatar={getCustomAvatar} />
        <BottomAnimation />
        <LeftAnimation />
        <RightAnimation />
      </main>
    </body>
  );
}

export default App;
