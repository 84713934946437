export const BottomAnimation = () => (
  <div className="anim anim--bottom z-5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1688 1500"
      width="1688"
      height="1500"
      preserveAspectRatio="xMaxYMax slice"
      style={{
        width: "100%",
        height: "100%",
        transform: "translate3d(0px, 0px, 0px)",
        contentVisibility: "visible",
      }}
    >
      <defs>
        <clipPath id="__lottie_element_126">
          <rect width="1688" height="1500" x="0" y="0"></rect>
        </clipPath>
        <clipPath id="__lottie_element_128">
          <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
        </clipPath>
        <clipPath id="__lottie_element_129">
          <path
            fill="#ffffff"
            clip-rule="nonzero"
            d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
            fill-opacity="1"
          ></path>
        </clipPath>
        <clipPath id="__lottie_element_189">
          <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
        </clipPath>
        <clipPath id="__lottie_element_190">
          <path
            fill="#ffffff"
            clip-rule="nonzero"
            d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
            fill-opacity="1"
          ></path>
        </clipPath>
      </defs>
      <g clip-path="url(#__lottie_element_126)">
        <g
          clip-path="url(#__lottie_element_189)"
          transform="matrix(0,1,1.0015000104904175,0,0,0)"
          opacity="1"
          style={{ display: "block" }}
        >
          <g clip-path="url(#__lottie_element_190)">
            <g
              transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.4792477488517761,0,482.44464111328125,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.38750216364860535,0,262.7000732421875,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.27721554040908813,0,105.91207885742188,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.2403918206691742,0,-30.119531631469727,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.8024939894676208,0,1129.4908447265625,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.6744676828384399,0,730.1572875976562,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.5132513046264648,0,417.35101318359375,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.34101924300193787,0,200.5652313232422,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.22042274475097656,0,58.14256286621094,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.1703125238418579,0,-45.89611053466797,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,1.258392095565796,0,1505.657470703125,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.9356011748313904,0,934.0265502929688,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.5900695323944092,0,556.2185668945312,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.39405518770217896,0,308.4035949707031,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.27779099345207214,0,136.5206756591797,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.2349196821451187,0,-2.407461166381836,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
          </g>
        </g>
        <g
          clip-path="url(#__lottie_element_128)"
          transform="matrix(0,1,-1.0015000104904175,0,1688,0)"
          opacity="1"
          style={{ display: "block" }}
        >
          <g clip-path="url(#__lottie_element_129)">
            <g
              transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.4792477488517761,0,482.44464111328125,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.38750216364860535,0,262.7000732421875,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.27721554040908813,0,105.91207885742188,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.2403918206691742,0,-30.119531631469727,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.8024939894676208,0,1129.4908447265625,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.6744676828384399,0,730.1572875976562,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.5132513046264648,0,417.35101318359375,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.34101924300193787,0,200.5652313232422,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.22042274475097656,0,58.14256286621094,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.1703125238418579,0,-45.89611053466797,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,1.258392095565796,0,1505.657470703125,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.9356011748313904,0,934.0265502929688,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.5900695323944092,0,556.2185668945312,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.39405518770217896,0,308.4035949707031,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.27779099345207214,0,136.5206756591797,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.2349196821451187,0,-2.407461166381836,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);
