// src/components/MainContent.js
import { Link } from "react-router-dom";

export const MainContent = ({ user, handleSignOut, getCustomAvatar }) => (
  <div className="mt-5 pt-15 z-10">
    <img src="/logo.svg" alt="Monika Logo" className="logo" />
    <div className="text-center">
      <h3 className="title">A moniker delivery system.</h3>

      {user?.displayName && (
        <div className="flex flex-col items-center pt-5">
          <span className="title">Welcome back {user.displayName.split(" ")[0]}</span>
          <img
            src={getCustomAvatar(user.displayName)}
            alt="Custom Avatar"
            className="w-10 h-10 rounded-full cursor-pointer my-5"
            onClick={handleSignOut}
          />
        </div>
      )}

      <nav className="flex flex-col items-center gap-5">
        <Link to="/projects">
          <button className="btn btn-secondary text-neutral">Projects</button>
        </Link>
        <Link to="/processes">
          <button className="btn btn-secondary text-neutral">Processes</button>
        </Link>
        <Link to="/trademarking">
          <button className="btn btn-secondary text-neutral">Trademarking</button>
        </Link>
        <Link to="/liked-names">
          <button className="btn btn-secondary text-neutral">Liked Names</button>
        </Link>
      </nav>
    </div>
  </div>
);

export default MainContent;
