export const RightAnimation = () => (
  <div className="anim anim--right">
    <svg
      viewBox="0 0 1500 1688"
      width="1500"
      height="1688"
      preserveAspectRatio="xMaxYMax slice"
      style={{
        width: "100%",
        height: "100%",
        transform: "translate3d(0px, 0px, 0px)",
        contentVisibility: "visible",
      }}
    >
      <defs>
        <clipPath id="__lottie_element_374">
          <rect width="1500" height="1688" x="0" y="0"></rect>
        </clipPath>
        <clipPath id="__lottie_element_376">
          <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
        </clipPath>
        <clipPath id="__lottie_element_377">
          <path
            fill="#ffffff"
            clip-rule="nonzero"
            d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
            fill-opacity="1"
          ></path>
        </clipPath>
        <clipPath id="__lottie_element_437">
          <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
        </clipPath>
        <clipPath id="__lottie_element_438">
          <path
            fill="#ffffff"
            clip-rule="nonzero"
            d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
            fill-opacity="1"
          ></path>
        </clipPath>
      </defs>
      <g clip-path="url(#__lottie_element_374)">
        <g
          clip-path="url(#__lottie_element_437)"
          transform="matrix(1,0,0,-1.0015000104904175,0,1688)"
          opacity="1"
          style={{ display: "block" }}
        >
          <g clip-path="url(#__lottie_element_438)">
            <g
              transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.4902619421482086,0,518.1962280273438,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.4082224667072296,0,286.6288146972656,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.2816087603569031,0,127.31857299804688,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.24878376722335815,0,-13.31737232208252,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.7774905562400818,0,1062.77490234375,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.6576719880104065,0,673.6580200195312,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.48674502968788147,0,374.6397399902344,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.31582415103912354,0,172.26116943359375,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.205200657248497,0,39.636436462402344,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.16572052240371704,0,-60.20586395263672,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,1.3024816513061523,0,1598.83251953125,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.9938627481460571,0,995.7991943359375,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.6220873594284058,0,596.5234375,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.41505134105682373,0,335.4264221191406,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.2847457230091095,0,157.8206329345703,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.2396436631679535,0,16.29468536376953,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
          </g>
        </g>
        <g
          clip-path="url(#__lottie_element_376)"
          transform="matrix(1,0,0,1.0015000104904175,0,0)"
          opacity="1"
          style={{ display: "block" }}
        >
          <g clip-path="url(#__lottie_element_377)">
            <g
              transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.4902619421482086,0,518.1962280273438,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.4082224667072296,0,286.6288146972656,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.2816087603569031,0,127.31857299804688,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.24878376722335815,0,-13.31737232208252,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.7774905562400818,0,1062.77490234375,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.6576719880104065,0,673.6580200195312,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.48674502968788147,0,374.6397399902344,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.31582415103912354,0,172.26116943359375,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.205200657248497,0,39.636436462402344,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.16572052240371704,0,-60.20586395263672,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,1.3024816513061523,0,1598.83251953125,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.9938627481460571,0,995.7991943359375,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.6220873594284058,0,596.5234375,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.41505134105682373,0,335.4264221191406,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.2847457230091095,0,157.8206329345703,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.2396436631679535,0,16.29468536376953,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);
