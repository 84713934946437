import { faHeart, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const LikedNamesPage = () => {
  const [loading, setLoading] = useState(true);
  const [likedNames, setLikedNames] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const host = process.env.REACT_APP_SERVER;

  useEffect(() => {
    const fetchLikedNames = async () => {
      try {
        const response = await fetch(`${host}/api/liked-names`);
        if (response.ok) {
          const data = await response.json();
          setLikedNames(data.data.likedNames);
        }
      } catch (error) {
        console.error("Failed to fetch liked names:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLikedNames();
  }, []);

  const handleSort = column => {
    setSortDirection(prevDirection => (sortColumn === column ? (prevDirection === "asc" ? "desc" : "asc") : "asc"));
    setSortColumn(column);
  };

  const getLastMutation = mutations => {
    if (!mutations || mutations.length === 0) return "-";
    return mutations[mutations.length - 1];
  };

  const sortedNames = [...likedNames].sort((a, b) => {
    if (!sortColumn) return 0;

    const direction = sortDirection === "asc" ? 1 : -1;
    let valueA = a[sortColumn];
    let valueB = b[sortColumn];

    // Special handling for mutations column
    if (sortColumn === "mutations") {
      valueA = getLastMutation(valueA);
      valueB = getLastMutation(valueB);
    }

    if (typeof valueA === "string") {
      return direction * valueA.localeCompare(valueB);
    }
    return direction * (valueA - valueB);
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <span className="loading loading-ring loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Breadcrumbs */}
      <div className="text-sm breadcrumbs mb-6">
        <ul className="flex flex-wrap">
          <li className="mr-2">
            <Link className="text-secondary flex items-center" to="/">
              <FontAwesomeIcon icon={faHome} className="mr-1" />
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link className="text-secondary flex items-center" to="/liked-names">
              <FontAwesomeIcon icon={faHeart} className="mr-1" />
              <span>Liked Names</span>
            </Link>
          </li>
        </ul>
      </div>

      {/* Stats Summary */}
      <div className="stats shadow mb-6">
        <div className="stat">
          <div className="stat-title">Total Liked Names</div>
          <div className="stat-value">{likedNames.length}</div>
        </div>
        <div className="stat">
          <div className="stat-title">Projects</div>
          <div className="stat-value">{new Set(likedNames.map(name => name.project_id)).size}</div>
        </div>
      </div>

      {/* Table */}
      <div className="bg-base-200 rounded-lg shadow-sm">
        <div className="overflow-x-auto">
          <table className="table table-xs">
            <thead>
              <tr>
                <th onClick={() => handleSort("name")} className="cursor-pointer hover:bg-base-300">
                  Name {sortColumn === "name" && (sortDirection === "asc" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("project_name")} className="cursor-pointer hover:bg-base-300">
                  Project {sortColumn === "project_name" && (sortDirection === "asc" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("trademark")} className="cursor-pointer hover:bg-base-300">
                  TM {sortColumn === "trademark" && (sortDirection === "asc" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("overall_grade")} className="cursor-pointer hover:bg-base-300">
                  Grade {sortColumn === "overall_grade" && (sortDirection === "asc" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("model")} className="cursor-pointer hover:bg-base-300">
                  Model {sortColumn === "model" && (sortDirection === "asc" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("mutations")} className="cursor-pointer hover:bg-base-300">
                  Last Mutation {sortColumn === "mutations" && (sortDirection === "asc" ? "↑" : "↓")}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedNames.map(name => (
                <tr key={name.id} className="hover:bg-base-300">
                  <td>
                    <Link
                      to={`/projects/${name.project_id}/names/${name.id}`}
                      className="text-secondary hover:underline"
                    >
                      {name.name}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/projects/${name.project_id}`} className="text-secondary hover:underline">
                      {name.project_name}
                    </Link>
                  </td>
                  <td>{name.trademark_effective || name.trademark || "-"}</td>
                  <td>{name.overall_grade || "-"}</td>
                  <td>
                    <Link to={`/processes/${name.model}`} className="text-secondary hover:underline">
                      {name.model}
                    </Link>
                  </td>
                  <td>
                    {getLastMutation(name.mutations) !== "-" && (
                      <Link
                        to={`/processes/${getLastMutation(name.mutations)}`}
                        className="text-secondary hover:underline"
                      >
                        {getLastMutation(name.mutations)}
                      </Link>
                    )}
                    {getLastMutation(name.mutations) === "-" && "-"}
                  </td>
                  <td>
                    <Link
                      to={`/projects/${name.project_id}/names/${name.id}`}
                      className="btn btn-xs btn-secondary text-neutral"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* No Results Message */}
      {likedNames.length === 0 && (
        <div className="text-center py-8">
          <p className="text-gray-500">No liked names found.</p>
        </div>
      )}
    </div>
  );
};

export default LikedNamesPage;
