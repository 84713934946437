import { faFolder, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ projectId }) => {
  return (
    <div className="text-sm breadcrumbs mb-6">
      <ul className="flex flex-wrap">
        <li className="mr-2">
          <Link className="text-secondary flex items-center" to="/">
            <FontAwesomeIcon icon={faHome} className="mr-1" />
            <span>Home</span>
          </Link>
        </li>
        <li className="mr-2">
          <Link className="text-secondary flex items-center" to="/projects">
            <FontAwesomeIcon icon={faFolder} className="mr-1" />
            <span>Projects</span>
          </Link>
        </li>
        <li>
          <Link className="text-secondary flex items-center" to={`/projects/${projectId}`}>
            <span>{projectId}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default BreadCrumbs;
