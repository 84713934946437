import { faHome, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import "../css/App.css";

function Processes() {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [processes, setProcesses] = useState([]);

  const host = process.env.REACT_APP_SERVER;

  const groupedProcesses = {};

  processes.forEach(d => {
    if (!groupedProcesses[d.type]) {
      groupedProcesses[d.type] = [];
    }
    groupedProcesses[d.type].push(d);
  });

  useEffect(() => {
    if (user === null) {
      navigate("/signin");
    }
  }, [user, navigate]);

  useEffect(() => {
    setLoading(true);
    const fetchNameData = async () => {
      try {
        const response = await fetch(`${host}/api/processes`);
        const processesData = await response.json();
        console.log(processesData);
        setProcesses(processesData.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNameData();
  }, []);

  if (!user?.email) {
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <div className="container mx-auto w-full pl-5">
      <div className="text-sm breadcrumbs mb-6">
        <ul className="flex flex-wrap">
          <li className="mr-2">
            <Link className="text-secondary flex items-center" to="/">
              <FontAwesomeIcon icon={faHome} className="mr-1" />
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link className="text-secondary flex items-center" to="/liked-names">
              <FontAwesomeIcon icon={faMicrochip} className="mr-1" />
              <span>Processes</span>
            </Link>
          </li>
        </ul>
      </div>

      <div>
        {Object.keys(groupedProcesses).map(type => (
          <article key={type} className="prose pb-10">
            <h2>{type}</h2>
            <ul>
              {groupedProcesses[type].map(d => (
                <li key={d.name}>
                  <Link className="text-secondary" to={`/processes/${d.function}`}>
                    {d.name}
                  </Link>
                </li>
              ))}
            </ul>
          </article>
        ))}
      </div>
      {/* <ul>
        {processes.map((d) => (
          <li key={d.name}>
            <Link className="text-secondary"  to={`/processes/${d.function}`}>{d.name}</Link>
          </li>
        ))}
      </ul> */}
    </div>
  );
}

export default Processes;
