export const TopAnimation = () => (
  <div className="anim anim--top z-5">
    <svg
      viewBox="0 0 1588 1400"
      width="1688"
      height="1400"
      preserveAspectRatio="xMaxYMax slice"
      style={{
        width: "100%",
        height: "100%",
        transform: "translate3d(0px, -60px, 0px)",
        contentVisibility: "visible",
      }}
    >
      <defs>
        <clipPath id="__lottie_element_2">
          <rect width="1688" height="1500" x="0" y="0"></rect>
        </clipPath>
        <clipPath id="__lottie_element_4">
          <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
        </clipPath>
        <clipPath id="__lottie_element_5">
          <path
            fill="#ffffff"
            clipRule="nonzero"
            d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
            fillOpacity="1"
          ></path>
        </clipPath>
        <clipPath id="__lottie_element_65">
          <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
        </clipPath>
        <clipPath id="__lottie_element_66">
          <path
            fill="#ffffff"
            clipRule="nonzero"
            d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
            fillOpacity="1"
          ></path>
        </clipPath>
      </defs>
      <g clip-path="url(#__lottie_element_2)">
        <g
          clip-path="url(#__lottie_element_65)"
          transform="matrix(0,1,1.0015000104904175,0,0,0)"
          opacity="1"
          style={{ display: "block" }}
        >
          <g clip-path="url(#__lottie_element_66)">
            <g
              transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.4398067593574524,0,354.4216003417969,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.31330469250679016,0,177.01348876953125,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.261483758687973,0,29.25746726989746,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.21034105122089386,0,-90.2864761352539,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.8920289874076843,0,1368.3941650390625,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.7346117496490479,0,932.475830078125,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.6081677675247192,0,570.2960815429688,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.43124061822891235,0,301.9194030761719,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.27493157982826233,0,124.41124725341797,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.1867559850215912,0,5.345733642578125,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,1.1005114316940308,0,1172.006591796875,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.7269718647003174,0,712.8243408203125,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.4754166901111603,0,411.8905944824219,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.31886982917785645,0,211.63739013671875,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.2528867721557617,0,60.247535705566406,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.21800349652767181,0,-69.37810516357422,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
          </g>
        </g>
        <g
          clip-path="url(#__lottie_element_4)"
          transform="matrix(0,1,-1.0015000104904175,0,1688,0)"
          opacity="1"
          style={{ display: "block" }}
        >
          <g clip-path="url(#__lottie_element_5)">
            <g
              transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.4398067593574524,0,354.4216003417969,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.31330469250679016,0,177.01348876953125,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.261483758687973,0,29.25746726989746,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,0.33458998799324036,-0.21034105122089386,0,-90.2864761352539,937.1737060546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.8920289874076843,0,1368.3941650390625,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.7346117496490479,0,932.475830078125,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.6081677675247192,0,570.2960815429688,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.43124061822891235,0,301.9194030761719,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.27493157982826233,0,124.41124725341797,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5645800232887268,0.1867559850215912,0,5.345733642578125,373.93853759765625)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                <path
                  fill="rgb(35,31,32)"
                  fill-opacity="1"
                  d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,1.1005114316940308,0,1172.006591796875,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.7269718647003174,0,712.8243408203125,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.4754166901111603,0,411.8905944824219,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.31886982917785645,0,211.63739013671875,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.2528867721557617,0,60.247535705566406,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
            <g
              transform="matrix(0,-0.5911399722099304,0.21800349652767181,0,-69.37810516357422,40.56934356689453)"
              opacity="1"
              style={{ display: "block" }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                <path
                  fill="rgb(242,239,233)"
                  fill-opacity="1"
                  d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);
