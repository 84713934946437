import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationTriangle,
  faFolder,
  faHeart as faHeartSolid,
  faHome,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { countryOptions } from "../variables/countryOptions";

function Name() {
  const { projectId, nameId } = useParams();
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [nameData, setNameData] = useState(null);
  const [mutationStatus, setMutationStatus] = useState(null);
  const [mutations, setMutations] = useState([]);
  const [status, setStatusUpdates] = useState([]);
  const [relatedNames, setRelatedNames] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [trademarkData, setTrademarkData] = useState(null);
  const [result, setResult] = useState([]);

  const host = process.env.REACT_APP_SERVER;

  const getCountryFlag = languageCode => {
    const countryOption = countryOptions.find(option =>
      Array.isArray(option.value) ? option.value.includes(languageCode) : option.value === languageCode
    );
    return countryOption ? countryOption.flag : null;
  };

  const handleFetchReport = async () => {
    const url = "https://dgwamab4o3.execute-api.us-east-1.amazonaws.com/test/tmview";

    const { countries, nice_classes } = { ...projectData };
    console.log("Fetching TM report:");
    console.log("countries", countries);
    console.log("nice_classes", nice_classes);
    try {
      const params = new URLSearchParams();
      params.append("q", nameData.name);

      if (countries.length > 0) {
        params.append("countries", countries.map(d => d).join());
      }
      if (nice_classes.length > 0) {
        params.append("nice_classes", nice_classes.map(d => d).join());
      }
      console.log("params", params.toString());

      // if (page) {
      //   params.append("page", page);
      // }

      // if (searchOption) {
      //   params.append("search_type", searchOption);
      // }

      const response = await fetch(`${url}?${params.toString()}`);
      // const response = await fetch(`${host}/api/trademark?${params.toString()}`);
      if (response.ok) {
        let data = await response.json();
        setTrademarkData(data);
        console.log('data["grade"]', data["grade"]);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMutateNames = () => {
    console.log("handleMutateNames");
    try {
      // Create an EventSource to listen for updates
      const eventSource = new EventSource(`${host}/api/projects/${projectId}/status`);
      setMutationStatus("running");

      // Add an event listener for updates
      eventSource.addEventListener("message", event => {
        const status = event.data.replace(/"/g, "");
        console.log("Received status update:", status);
        setStatusUpdates(prevStatusUpdates => ({
          ...prevStatusUpdates,
          [status]: true,
        }));
        // Update your UI or take action based on the status update
      });

      // Add an event listener for mutation results
      eventSource.addEventListener("mutation_results", event => {
        const results = event.data;
        console.log("Received mutation results:", results);

        // Update the mutations state
        setMutations(prevMutations => [...prevMutations, results]);
      });

      // Close the event source when you're done
      eventSource.addEventListener("end", () => {
        setMutationStatus("completed");
        eventSource.close();
      });

      // Send a POST request to initiate the name generation
      fetch(`${host}/api/projects/${projectId}/names/${nameId}/mutate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error("Error:", error);
      setMutationStatus("failed");
    }
  };

  const fetchNameData = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${host}/api/projects/${projectId}/names/${nameId}`);
      const jsonData = await response.json();
      // console.log("Namedata", jsonData.data.name);
      setNameData(jsonData.data.name);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNameData();
  }, [projectId, nameId]);

  const fetchProjectData = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${host}/api/projects/${projectId}`);
      const jsonData = await response.json();
      // console.log("projectData", jsonData.data.project);
      setProjectData(jsonData.data.project);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Restore scroll position
  }, []);

  useEffect(() => {
    fetchProjectData();
  }, [projectId, nameId]);

  useEffect(() => {
    const fetchRelatedNames = async () => {
      try {
        // Fetch names with the same "original" value

        // Check if nameData is not null before accessing its 'name' property
        if (nameData && nameData.name) {
          console.log("Name:", nameData);
          const response = await fetch(`${host}/api/projects/${projectId}/names/original/${nameData.name}`);
          const jsonData = await response.json();
          console.log("Original", jsonData);
          if (jsonData.names !== null) {
            setMutations(jsonData.names);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchRelatedNames();
  }, [nameData, projectId, status]);

  const handleBookmarkToggle = async name => {
    console.log("handleBookmarkToggle", name);
    const updatedName = { ...name, bookmarked: !name.bookmarked };
    try {
      const response = await fetch(`${host}/api/projects/${projectId}/names/${name.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ bookmarked: !name.bookmarked }),
      });
      if (response.ok) {
        // Update the state here
        // const updatedNameData = name.map((n) => (n.id === updatedName.id ? updatedName : n));
        setNameData(updatedName);
      } else {
        console.error("Failed to update bookmark status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const sortedNameData = [...mutations].sort((a, b) => {
    if (sortColumn) {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      return compareValues(valueA, valueB);
    }
    return 0;
  });

  const handleSort = column => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleMetricFetch = async metricType => {
    // setLoading(true);

    try {
      const requestData = { function: `get_${metricType}`, word: nameData.name };
      const response = await fetch(`${host}/api/process`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        const result = JSON.parse(data.message)[0];
        await updateMetricInDatabase(metricType, result);
      } else {
        console.error(`API request for ${metricType} failed`);
      }
    } catch (error) {
      console.error(`Error sending API request for ${metricType}:`, error);
    } finally {
      // setLoading(false);
    }
  };

  const handleGradeFetch = async () => {
    // setLoading(true) ;
    console.log("nameData", nameData);

    try {
      const requestData = {
        function: "get_overall_grade",
        trademark: nameData.trademark,
        evocativeness: nameData.evocativeness,
        uniqueness: nameData.uniqueness,
        pronounceability: nameData.pronounceability,
      };
      const response = await fetch(`${host}/api/process`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        const result = JSON.parse(data.message)[0];
        await updateMetricInDatabase("overall_grade", result);
      } else {
        console.error(`API request for ${"overall_grade"} failed`);
      }
    } catch (error) {
      console.error(`Error sending API request for ${"overall_grade"}:`, error);
    } finally {
      // setLoading(false);
    }
  };

  const updateMetricInDatabase = async (metricType, metricValue) => {
    try {
      const putData = { [metricType]: metricValue };
      const putResponse = await fetch(`${host}/api/projects/${projectId}/names/${nameId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(putData),
      });

      if (putResponse.ok) {
        console.log(`${metricType} updated successfully in the database`);
        setNameData(prevNameData => ({
          ...prevNameData,
          [metricType]: metricValue,
        }));
      } else {
        console.error(`Failed to update ${metricType} in the database`);
      }
    } catch (error) {
      console.error(`Error updating ${metricType} in the database:`, error);
    }
  };

  const compareValues = (valueA, valueB) => {
    if (valueA === null) {
      return sortDirection === "asc" ? 1 : -1;
    }
    if (valueB === null) {
      return sortDirection === "asc" ? -1 : 1;
    }

    if (typeof valueA === "string" && typeof valueB === "string") {
      const trademarkOrder = ["A", "B", "C", "D", "F"];
      const indexA = trademarkOrder.indexOf(valueA);
      const indexB = trademarkOrder.indexOf(valueB);

      if (indexA !== -1 && indexB !== -1) {
        return sortDirection === "asc" ? indexA - indexB : indexB - indexA;
      } else if (indexA !== -1) {
        return sortDirection === "asc" ? -1 : 1;
      } else if (indexB !== -1) {
        return sortDirection === "asc" ? 1 : -1;
      } else {
        return sortDirection === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
    } else {
      return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
    }
  };

  const handleDelete = async (nameId, nameName) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete ${nameName}?`);

    if (confirmDelete) {
      try {
        const response = await fetch(`${host}/api/names/${nameId}`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          // Name successfully deleted, you can update the UI accordingly
          fetchNameData(); // Refetch data to update the name list
        } else {
          // Handle error here, e.g., show an error message
          console.error("Failed to delete name");
        }
      } catch (error) {
        // Handle error here, e.g., show an error message
        console.error("Failed to delete name", error);
      }
    }
  };

  function MetricDisplay({ metricName, metricValue, onFetch }) {
    const nameDataMapping = {
      A: 100,
      B: 80,
      C: 60,
      D: 40,
      F: 0,
      "": 0,
      null: 0,
    };
    // <td className="border px-2 py-1">{name.trademark === null ? "" : name.trademark === "" ? "-" : name.trademark}</td>

    return (
      <div className="pt-5">
        <progress
          className="progress progress-primary w-30 pr-5"
          value={nameDataMapping[metricValue]}
          max="100"
        ></progress>
        {metricValue !== null ? (
          <strong>{metricValue === "" ? "-" : metricValue}</strong>
        ) : (
          <button className="btn btn-secondary w-15" onClick={onFetch}>
            ?
          </button>
        )}
        {" " + metricName}
      </div>
    );
  }

  if (loading) {
    return <span className="loading loading-ring loading-xs"></span>;
  }

  if (!nameData) {
    return <p>Name not found.</p>;
  }

  return (
    <div className="container mx-auto w-full pl-5">
      <div className="text-sm breadcrumbs pb-5">
        <ul>
          <li>
            <Link className="text-secondary" to={`/`}>
              <FontAwesomeIcon icon={faHome} size="1x" />
              <p className="pl-2 text-secondary">Home</p>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/projects`}>
              <FontAwesomeIcon icon={faFolder} size="1x" />
              <p className="pl-2 text-secondary">Projects</p>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/projects/${nameData.project_id}`}>
              <p className="pl-2 text-secondary">{nameData.project_id}</p>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/projects/${projectId}/names/${nameData.id}`}>
              <p className="pl-2 text-secondary">{nameData.name}</p>
            </Link>
          </li>
        </ul>
      </div>
      <div className="">
        <div className="w-full lg:w-1/2 p-5">
          <article className="prose ">
            <h1 className="text-2xl font-semibold">
              <FontAwesomeIcon
                icon={nameData.bookmarked ? faHeartSolid : faHeart}
                size="1x"
                style={{ color: nameData.bookmarked ? "#a76ca1" : "", marginRight: "10px" }}
                onClick={() => handleBookmarkToggle(nameData)}
              />
              {nameData.name}{" "}
              {nameData.sensitivity === true ? (
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#a76ca1" }} size="1x" />
              ) : (
                ""
              )}
            </h1>
            <blockquote className="italic border-l-4 border-accent-500 pl-4 py-2 mb-4">
              <p>{nameData.definition}</p>
            </blockquote>
          </article>
          <ul className="steps">
            <li className="step step-primary">
              <h3>Model:</h3>
              <Link className="text-secondary" to={`/processes/${nameData.model}`}>
                <p className="text-secondary">{nameData.model}</p>
              </Link>
            </li>

            {nameData.link ? (
              <li className="step step-primary">
                <h3>Source:</h3>
                <Link className="text-secondary" to={nameData.link}>
                  <p className="text-secondary">Link</p>
                </Link>
              </li>
            ) : (
              <></>
            )}

            {nameData.original ? (
              <li className="step step-primary">
                <h3>Original:</h3>
                <Link className="text-secondary" to={`/projects/${projectId}/names/${nameData.originalId}`}>
                  <p className="text-secondary">{nameData.original}</p>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {nameData.mutations.length > 0 ? (
              nameData.mutations.map(d => (
                <li className="step step-primary">
                  <h3>Mutation:</h3>
                  <Link className="text-secondary" to={`/processes/${d}`}>
                    <p className="text-secondary">{d}</p>
                  </Link>
                </li>
              ))
            ) : (
              <></>
            )}
            <li className="step step-primary">
              <h3>Name:</h3>
              <Link className="text-secondary" to={`/projects/${projectId}/names/${nameData.id}`}>
                <p className="text-secondary">{nameData.name}</p>
                {nameData.language && <span className="mr-2">({nameData.language})</span>}
              </Link>
            </li>
          </ul>
          <p>{nameData.description}</p>
          <div className="">
            <MetricDisplay
              metricName="Overall"
              metricValue={nameData.overall_grade}
              onFetch={() => handleGradeFetch()}
            />
            <MetricDisplay
              metricName="Trademark"
              metricValue={nameData.trademark ? nameData.trademark : nameData.trademark}
              onFetch={() => handleMetricFetch("trademark")}
            />
            {nameData.trademark_effective !== null ? (
              <MetricDisplay metricName="Trademark Effective" metricValue={nameData.trademark_effective} />
            ) : (
              <></>
            )}
            <MetricDisplay
              metricName="Uniqueness"
              metricValue={nameData.uniqueness}
              onFetch={() => handleMetricFetch("uniqueness")}
            />
            <MetricDisplay
              metricName="Pronounceability"
              metricValue={nameData.pronounceability}
              onFetch={() => handleMetricFetch("pronounceability")}
            />
            <MetricDisplay
              metricName="Evocativeness"
              metricValue={nameData.evocativeness}
              onFetch={() => handleMetricFetch("evocativeness")}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div>
          <article className="prose ">
            <h3 className=" font-semibold">Mutations</h3>
          </article>
          {mutations.length > 0 ? (
            <div className="table-container py-5">
              <table className="table table-xs">
                <thead>
                  <tr>
                    <th className="sticky top-0 px-2 py-1 cursor-pointer" onClick={() => handleSort("bookmarked")}>
                      Liked {sortColumn === "bookmarked" && (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                    <th className="sticky top-0 px-2 py-1 cursor-pointer" onClick={() => handleSort("name")}>
                      Name {sortColumn === "name" && (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                    <th className="sticky top-0 px-2 py-1 cursor-pointer" onClick={() => handleSort("overall_grade")}>
                      Grade {sortColumn === "overall_grade" && (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                    <th className="sticky top-0 px-2 py-1 cursor-pointer" onClick={() => handleSort("trademark")}>
                      Trademark {sortColumn === "trademark" && (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                    <th className="sticky top-0 px-2 py-1 cursor-pointer" onClick={() => handleSort("uniqueness")}>
                      Uniqueness {sortColumn === "uniqueness" && (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="sticky top-0 px-2 py-1 cursor-pointer"
                      onClick={() => handleSort("pronounceability")}
                    >
                      Pronounceability {sortColumn === "pronounceability" && (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                    <th className="sticky top-0 px-2 py-1 cursor-pointer" onClick={() => handleSort("evocativeness")}>
                      Evocativeness {sortColumn === "evocativeness" && (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                    <th className="sticky top-0 px-2 py-1 cursor-pointer" onClick={() => handleSort("mutation")}>
                      Mutation {sortColumn === "model" && (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedNameData.map(name => (
                    <tr key={name.id}>
                      <td className="border px-2 py-1 text-center">
                        <FontAwesomeIcon
                          icon={name.bookmarked ? faHeartSolid : faHeart}
                          size="1x"
                          style={{ color: name.bookmarked ? "#a76ca1" : "" }}
                          onClick={() => handleBookmarkToggle(name)}
                        />
                      </td>

                      <td className="border px-2 py-1 truncate">
                        <Link className="text-secondary" to={`/projects/${name.project_id}/names/${name.id}`}>
                          {name.name}
                        </Link>
                      </td>
                      <td className="border px-2 py-1">
                        {name.overall_grade === null ? "" : name.overall_grade === "" ? "-" : name.overall_grade}
                      </td>
                      <td className="border px-2 py-1">
                        {name.trademark_effective === null
                          ? ""
                          : name.trademark_effective === ""
                          ? name.trademark === null
                            ? ""
                            : name.trademark === ""
                            ? "-"
                            : name.trademark
                          : name.trademark_effective}
                      </td>
                      <td className="border px-2 py-1">
                        {name.uniqueness === null ? "" : name.uniqueness === "" ? "-" : name.uniqueness}
                      </td>
                      <td className="border px-2 py-1">
                        {name.pronounceability === null
                          ? ""
                          : name.pronounceability === ""
                          ? "-"
                          : name.pronounceability}
                      </td>
                      <td className="border px-2 py-1">
                        {name.evocativeness === null ? "" : name.evocativeness === "" ? "-" : name.evocativeness}
                      </td>
                      <td className="border px-2 py-1">
                        <Link className="text-secondary" to={`/processes/${name.mutations.at(-1)}`}>
                          {name.mutations.at(-1)}
                        </Link>
                      </td>

                      <td className="border px-4 py-2">
                        <FontAwesomeIcon
                          icon={faTrash}
                          size="1x"
                          className="text-secondary"
                          onClick={() => handleDelete(name.id, name.name)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div></div>
          )}
          <button
            className="btn btn-secondary mt-5"
            onClick={() => handleMutateNames()}
            disabled={mutationStatus === "running"}
          >
            {mutationStatus === "running"
              ? "RUNNING..."
              : mutationStatus === "completed"
              ? "COMPLETED"
              : mutationStatus === "failed"
              ? "FAILED"
              : "MUTATE"}
          </button>
        </div>
        <div className="divider"></div>
        <article className="prose mb-5">
          <h3 className=" font-semibold">Detailed Trademark Report</h3>
          <button className="btn btn-secondary mt-5" onClick={() => handleFetchReport()}>
            FETCH
          </button>
        </article>
        {trademarkData ? (
          <div>
            <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
              <h1>{trademarkData.name}</h1>
              <div>
                <strong>Grade:</strong> {trademarkData.grade}
              </div>
              <div>
                <strong>Criteria:</strong> {trademarkData.criteria}
              </div>
              <div>
                <strong>Countries:</strong> {trademarkData.countries.join(", ")}
              </div>
              <div>
                <strong>Nice Classes:</strong> {trademarkData.nice_classes.join(", ")}
              </div>
              <div>
                <strong>Trademark Status:</strong> {trademarkData.tm_status.join(", ")}
              </div>
              <div>
                <strong>Results Count:</strong> {trademarkData.count}
              </div>
              <div>
                <strong>URL:</strong>{" "}
                <Link className="text-secondary" to={trademarkData.url}>
                  View Trademarks
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Name;
